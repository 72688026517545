import React from 'react';
import ScrollArw from './../inc/ScrollArw';
import MenuToggleBtn from './../inc/MenuToggleBtn';

class Intro extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let page_id = this.props.page_id;
    let sectionData = this.props.pages.pageData[page_id] ? this.props.pages.pageData[page_id].acf.page_content : [];

    return (
        <section className="section">
            <MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
            <div className="tmv__intro">
                <div className="main-container">
                    <div className="content-area">
                        <div className="logo-grid-2">
                            <article className="font-lg desc font-bold" dangerouslySetInnerHTML={{ __html:sectionData }} />
                        </div>
                    </div>
                </div>
            </div>
            <ScrollArw {...this.props} fullpageApi={this.props.fullpageApi} />
        </section>
    );
  }
}

export default Intro;