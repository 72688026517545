import React from 'react';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        let {fullpageApi} = this.props;
        const header = document.querySelector('header');
        e.stopPropagation();
        e.currentTarget.closest('.tmv__team--member').classList.remove('is-showing');
        e.currentTarget.closest('.tmv__team--member').querySelector('.tmv__team--member-dtls').removeAttribute('style');
        setTimeout(function(){
            header.style.display = 'block';
        }, 300)
        fullpageApi.setAllowScrolling(true);
    }

    render() {
        let member = this.props.member;
        let _this = this;
        return (
            <div className="tmv__team--member-dtls">
                <a href="javascript:" className="btn-closemenu" onClick={this.handleClick}></a>
                <div className="main-container">
                    <div className="grid">
                        <figure><img className="w-100" src={member.modal_image.url} alt={member.name} title={member.name}/></figure>
                        <article>
                            <p><b>{member.name}</b></p>
                            <p>{member.designation}</p>
                            <div className="desc" dangerouslySetInnerHTML={{__html: member.details}} />
                        </article>
                    </div>

                </div>
            </div>
        );
    }
}

export default Modal;