//Load posts
export const fetchJournalsBegin = () => ({
	type: 'FETCH_JOURNALS_BEGIN'
});

export const fetchJournalsSuccess = journals => ({
	type: 'FETCH_JOURNALS_SUCCESS',
	payload: journals
});

export const fetchJournalsError = error => ({
	type: 'FETCH_JOURNALS_FAILURE',
	payload: { error }
});

export function fetchJournals(pg = 1) {
	return dispatch => {
		dispatch(fetchJournalsBegin());
		var formData = new FormData();
		formData.append('pg', pg);
		return fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_API_CUSTOM_PREFIX + 'journals-fetch/', {
			crossDomain: true,
			method: 'POST',
			//headers: { 'Content-Type': 'application/json' },
			body: formData
		})
			.then(handleErrors)
			.then(response => response.json())
			.then(json => {
				dispatch(fetchJournalsSuccess(json));
			})
			.catch(error => dispatch(fetchJournalsError(error)));
	};
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
}