import React from 'react';
import ScrollArw from './ScrollArw';
import MenuToggleBtn from './MenuToggleBtn';




class SiteFooter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {
            pinterest_url,
            twitter_url, 
            instagram_url, 
            facebook_url, 
            linkedin_url, 
            contact_number, 
            contact_email, 
            copyright, 
            contact_heading, 
            office_address,
            logos
        } = this.props.options.options;
        let cls = this.props.className ? ' '+this.props.className : ''
        return (
            <footer id="footer" data-hammenu="white" className={`posiR section${cls}`}>
                {!this.props.nScroll &&
                    <MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
                }

                <div className="content-area">
                    <div className="main-container flex-centerY">
                        <div className="logo-grid-2">
                            <article className="font-lg desc font-bold" dangerouslySetInnerHTML={{__html : contact_heading}} />

                            <address className="font-lg">
                                <p>Contact us at</p>
                                <p><a href={`tel:${contact_number}`}>{contact_number}</a></p>
                                <p><a href={`mailto:${contact_email}`}>{contact_email}</a></p>
                            </address>
                        </div>

                        <div className="logo-grid-2">
                            <address>
                                {pinterest_url != '' &&
                                    <p><a href={pinterest_url} target="_blank">Pinterest</a></p>
                                }
                                {instagram_url != '' &&
                                    <p><a href={instagram_url} target="_blank">Instagram</a></p>
                                }
                                {facebook_url != '' &&
                                    <p><a href={facebook_url} target="_blank">Facebook</a></p>
                                }
                                {linkedin_url != '' &&
                                    <p><a href={linkedin_url} target="_blank">LinkedIn</a></p>
                                }
                            </address>

                            <div className="inner-flex">
                                <address dangerouslySetInnerHTML={{__html: office_address}} />
                                <figure className="logos" dangerouslySetInnerHTML={{__html:logos}} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.fullpageApi &&   
                    <ScrollArw {...this.props} fullpageApi={this.props.fullpageApi} moveTo={1} cls='dir-up'/>
                }
                {this.props.nScroll &&   
                    <ScrollArw {...this.props} nScroll={true} cls='dir-up'/>
                }
            </footer>
        );
    }
}
export default SiteFooter;