import React from 'react';
import Header from './../inc/Header';
import Loader from './../inc/Loader';
import Menu from './../inc/Menu';
import TmvIntro from './TmvIntro';
import MainSlider from './MainSlider';
import SiteFooter from './../inc/SiteFooter';
import { fetchMenu } from "./../../actions/menuActionCreators";
import { fetchPage } from "./../../actions/pageActionCreators";
import { fetchOptions } from "./../../actions/optionActionCreators";
import { fetchProjects } from "./../../actions/projectActionCreators";
import { fetchPosts } from "./../../actions/postActionCreators";
import ReactFullpage from '@fullpage/react-fullpage';
import ScrollToTop from './../inc/ScrollToTop';

import { gsap } from "gsap";


const page_id = '5';

class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoading: true
		}
	}
	componentDidMount() {
		let before = performance.now();
		let _this = this;
		Promise.all([
			this.props.dispatch(fetchMenu('menu')),
			this.props.dispatch(fetchPage(page_id)),
			this.props.dispatch(fetchProjects()),
			this.props.dispatch(fetchOptions()),
			this.props.dispatch(fetchPosts('homepage_sliders')),
		]).then(() => {
			let after = performance.now();
			let diff = after-before;
			let media_length = 3000;
			if(diff < media_length){
				setTimeout(() => {
					_this.setState({
						pageLoading: false
					})
				},(media_length - diff))
			}else{
				_this.setState({
					pageLoading: false
				})
			}
			
		});
	}

	render() {
		let _this = this;
		let { menu } = this.props.menu;

		const Fullpage = () => (
			<ReactFullpage
				normalScrollElements={'nav'}
				scrollOverflow={true}
				licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE}
				offsetSections={true}
				// responsiveWidth={process.env.REACT_APP_FULLPAGE_BREAKPOINT}
				scrollingSpeed={500}
				css3={true}
				afterLoad={
                    function (origin, destination, direction) {
                        var theme = destination.item.dataset ? destination.item.dataset.hammenu : false;
                        let header = document.querySelector('header');
						const btmarw = document.querySelector('.btn--bottom-arw');
                        if(theme){
							header.classList.add('white');
							if(btmarw)
                            	btmarw.classList.add('white');
                        }
                        else{
							header.classList.remove('white');
							if(btmarw)
                            	btmarw.classList.remove('white');
                        }
                        if(destination.isLast){
							if(btmarw)
                            	btmarw.classList.add('dir-up');
                        }
                        else{
							if(btmarw)
                            	btmarw.classList.remove('dir-up');
                        }
                        if(destination.isFirst){
							header.querySelector('.tmv__text').style.opacity = 1;
                        }
                        else{
							header.querySelector('.tmv__text').style.opacity = 0;
						}

						if(destination.item.querySelector('.tmv__intro')){
							gsap.to(".tmv__intro article > *", {
								autoAlpha: 1,
								stagger: 0.3, 
								duration: 0.9,
								ease: "Power0.easeNone"
							});
						}
                    }
                }
                onLeave={
                    function (origin, destination, direction) {
                        let header = document.querySelector('header');
                        //console.log(destination)
                        if(destination.isFirst){
                            header.querySelector('.tmv__text').style.opacity = 1;
                        }
                        else{
                            header.querySelector('.tmv__text').style.opacity = 0;
                        }
                        //currentIndex = destination.index;
                    }
                }
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper>
							<MainSlider {..._this.props} fullpageApi={fullpageApi} />
							<TmvIntro {..._this.props} page_id={page_id}  {..._this.props} fullpageApi={fullpageApi}  />
							<SiteFooter {..._this.props} {..._this.props} fullpageApi={fullpageApi} />
						</ReactFullpage.Wrapper>
					)
				}}
			/>
		);

		return (
			<main className="tmv__home" >
				{this.state.pageLoading &&
					<Loader />
				} 
				<ScrollToTop />
				<Header headerTheme={'white'} {..._this.props} fullpage={true} />
				{<a href="javascript:" className="btn--bottom-arw ph-arw"></a> /* fake :-) :-) :-) :-) */}
				<Fullpage {..._this.props} />
				{menu.primary &&
                    <Menu {...this.props} />
                }
			</main>
		);
	}
}

export default Index;