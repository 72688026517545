import React from 'react';
import Modal from './Modal';
import ScrollArw from './../inc/ScrollArw';
import MenuToggleBtn from './../inc/MenuToggleBtn';

class People extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        let teamMembers = document.querySelectorAll('.tmv__team--member');
        const header = document.querySelector('header');

        Array.from(teamMembers).forEach(function(tm){
            tm.addEventListener('mouseover', function(){
                [...teamMembers].forEach(x => x.classList.add(['fade-in']));
                this.classList.remove(['fade-in']);
            });
            tm.addEventListener('mouseleave', function(){
                [...teamMembers].forEach(x => x.classList.remove(['fade-in']));
            });
        });
    }

    handleClick(e){
        let {fullpageApi} = this.props;
        const header = document.querySelector('header');

        let scrollElem = document.querySelector('.teamSection  .fp-scroller');
        if(scrollElem){
             // get current transform values 
            let transform = scrollElem.style['transform'].replace(/\D/g, "");

            // extract only transform translateY value
            let translateY = transform.substring(1, transform.length-1);

            e.currentTarget.querySelector('.tmv__team--member-dtls').style['transform'] = `translateY(${translateY}px)`;
        }
        

        e.currentTarget.classList.add('is-showing');
        header.style.display = 'none';
        fullpageApi.setAllowScrolling(false);
    }

    render() {
        let _this = this;
        let page_id = this.props.page_id;
        let members = this.props.pages.pageData[page_id] ? this.props.pages.pageData[page_id].acf.members : [];
        if (!members.length) return  <section className="section" />;
        let featured = [];
        let others = [];

        featured = members.filter((item, key) => {
            return Number(item.featured) === 1
        });
        others = members.filter((item, key) => {
            return Number(item.featured) === 0
        });
        let featured_html = featured.map((member, key) => {
            return (
                <div className="tmv__team--member" key={key} onClick={this.handleClick}>
                    <figure><img src={member.listing_image.url} alt={member.name} title={member.name} /></figure>
                    <h6 className="--name"><b>{member.name}</b></h6>
                    <p className="--degination">{member.designation}</p>

                    <Modal {..._this.props} member={member} fullpageApi={this.props.fullpageApi}/>
                </div>
            )

        })

        let others_html = others.map((member, key) => {
            return (
                <div className="tmv__team--member" key={key} onClick={this.handleClick}>
                    <figure><img src={member.listing_image.url} alt={member.name} title={member.name} /></figure>
                    <h6 className="--name"><b>{member.name}</b></h6>
                    <p className="--degination">{member.designation}</p>

                    <Modal {..._this.props} member={member} fullpageApi={this.props.fullpageApi}/>
                </div>
            )

        })
        return (
            <section className="section teamSection">
                <MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
                <div className="tmv__team content-area">
                    <div className="main-container">
                        <div className="grid">{featured_html}</div>
                        <div className="grid">{others_html}</div>
                    </div>
                </div>
                <ScrollArw {..._this.props} fullpageApi={_this.props.fullpageApi} />
            </section>

        );
    }
}

export default People;