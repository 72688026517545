import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import TmvLogo from './../inc/TmvLogo';
import ScrollArw from './../inc/ScrollArw';
import MenuToggleBtn from './../inc/MenuToggleBtn';

import {
    NavLink,
    Link
} from 'react-router-dom'


class MainSlider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let _this = this;
        let sliders = Object.keys(this.props.posts.data).length ? this.props.posts.data.homepage_sliders : [];
        
        let sliderData = sliders && sliders.map((item, key) => {
            let sequence = ("0" + (Number(key) + 1) ).slice(-2);
            let project_subtitle = item.acf.project ? item.acf.project.project_subtitle : '';

            // letter split
            // let post_title = item.acf.project ? item.acf.project.post_title.split('').map((ltr, key) => {
            //     ltr = ltr.toString() != ' ' ? ltr : '&nbsp;';
            //     return `<span style="--i: ${1+.1*key}">${ltr}</span>`;
            // }).join('') : '';


            // word and letter split
            let post_title = item.acf.project ? item.acf.project.post_title.split(' ').map((word, wordkey) => {
              
                let post_title_ltr_split = word.split('').map((ltr, ltrkey) => {
                    return `<span style="--i: ${1+.1*ltrkey}">${ltr}</span>`;
                }).join('');
            
                return `<div class="d-inline-block">${post_title_ltr_split}&nbsp;</div>`;
            
            }).join('') : '';


            return (
                <div key={key} className={item.acf.image_type}>
                        <div className="main-container">
                            <figure className="hero-fig hvr-forefront">
                                <img className="fullScreen" src={item.acf.slider_image.url} alt={item.acf.project.post_title} title={item.acf.project.post_title} />
                                <figcaption>
                                    <h6 className="title" data-counter={sequence} dangerouslySetInnerHTML={{__html:post_title}} />
                                    {project_subtitle != '' &&
                                        <p className="sub-title">{project_subtitle}</p>
                                    }
                                </figcaption>
                                <Link to={`${process.env.PUBLIC_URL}/project/${item.acf.project.post_name}`} className="project-link"></Link>
                            </figure>
                        </div>
                    <TmvLogo {...this.props} />
                </div>
            )
        })

        let carouselOption = (sliders.length > 1) ? true : false;

        return (
            <section className="section bg-black" data-hammenu="white">
                <MenuToggleBtn {..._this.props} fullpageApi={_this.props.fullpageApi} />
                <div className="tmv__hero">
                    <AliceCarousel 
                        autoPlay={carouselOption}
                        autoPlayInterval="3000"
                        animationType="fadeout"
                        infinite={carouselOption}
                        disableDotsControls="true"
                        disableButtonsControls="false"
                        // mouseTracking="true"
                        items={sliderData} 
                    />
                </div>
                <ScrollArw {..._this.props} fullpageApi={_this.props.fullpageApi} moveTo={2} />
            </section>
        );
    }
}

export default MainSlider;
