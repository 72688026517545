import React from 'react';
import {
    NavLink,
    Link
} from 'react-router-dom'
import ScrollArw from './../../inc/ScrollArw';
import MenuToggleBtn from './../../inc/MenuToggleBtn';

class RelatedProjects extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let projectGrids = document.querySelectorAll('.tmv__project--item');

        Array.from(projectGrids).forEach(function(tm){
            tm.addEventListener('mouseover', function(){
                [...projectGrids].forEach(x => x.classList.add(['fade-in']));
                this.classList.remove(['fade-in']);
            });
            tm.addEventListener('mouseleave', function(){
                [...projectGrids].forEach(x => x.classList.remove(['fade-in']));
            });
        });
    }

    render() {
        let handleClickTop = this.props.handleClickTop;
        let { related_projects } = this.props;
        if (!related_projects.length) return [];
        let related_project_html = related_projects.map((item, key) => {
            let imgName = item.hero_image && item.hero_image.sizes ? item.hero_image.sizes['2-bottom-left'] : false;
            let sequence = ("0" + (Number(key) + 1) ).slice(-2);
            return (
                <figure className="tmv__project--item">
                    <Link to={`${process.env.PUBLIC_URL}/project/${item.post_name}`} key={key}>
                        <span><img src={imgName} alt={item.post_title} title={item.post_title} /></span>
                        <figcaption>
                            {item.post_title != '' &&
                                <h6 className="title" data-counter={sequence}>{item.post_title}</h6>
                            }
                            {item.project_subtitle != '' &&
                                <p className="sub-title">{item.project_subtitle}</p>
                            }
                        </figcaption>
                    </Link>
                </figure>
            )
        })
        return (
            <section className="section">
                <MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
                <div className="main-container">
                    <div className="tmv__related-projects fullH">
                        <div className="tmv__related-projects--grid">
                            {related_project_html}
                        </div>
                    </div>
                </div>
                <ScrollArw {...this.props} fullpageApi={this.props.fullpageApi} />
            </section>
        );
    }
}

export default RelatedProjects;