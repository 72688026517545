import React from 'react';
import SwiperCore, { Autoplay, A11y, EffectFade } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import ScrollArw from './../../inc/ScrollArw';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import MenuToggleBtn from './../../inc/MenuToggleBtn';

class HeroLayout extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() { }

    render() {
        let { details } = this.props;
        let sliders = details.sliders.length ? details.sliders : [];
        let sliderData = sliders && sliders.map((item, key) => {
            let slide = '';
            if (item.image_video == 1) {
                slide = <img className="fullScreen" src={item.image.url} alt={details.post_title} title={details.post_title} />;
            } else {
                slide = (
                    <div className="videobg">
                        <div className="videobg-width">
                            <div className="videobg-aspect">
                                <div className="videobg-make-height">
                                    <div className="videobg-hide-controls">
                                        {
                                            item.video.platform == 1 &&
                                                <iframe src={`https://player.vimeo.com/video/${item.video.video_id}?autoplay=1&amp;loop=1&amp;autopause=0&amp;title=0&amp;byline=0&amp;portrait=0&amp;background=1`} ></iframe>
                                        }
                                        {
                                            item.video.platform == 2 &&
                                                <iframe src={`https://www.youtube.com/embed/${item.video.video_id}?version=3&autoplay=1&controls=0&showinfo=0&loop=1&modestbranding=1&autohide=1`} ></iframe>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            return sliders.length > 1 ? (
                <SwiperSlide key={key}>
                    {slide}
                </SwiperSlide>
            ) : slide;
        })
        return (
            <section className="section section-hero" data-hammenu="white">
                <MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
                <script src="https://player.vimeo.com/api/player.js"></script>
                <div className="layout layout-hero">
                    {
                        sliders.length > 1 &&
                            <Swiper effect="fade" loop={true} autoplay={true}>
                                {sliderData}
                            </Swiper>
                    }

                    {
                        sliders.length == 1 &&
                            sliderData
                    }
                    
                    {details.post_title != '' &&
                        <h1 className="project-title">{details.post_title}</h1>
                    }
                </div>
                <ScrollArw {...this.props} fullpageApi={this.props.fullpageApi} />
            </section>
        );
    }
}

export default HeroLayout;