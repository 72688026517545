import React from 'react';
import {
	NavLink,
	Link
} from 'react-router-dom'


class Menu extends React.Component {
	constructor(props) {
		super(props);
        this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		// adjust fullscreen height
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		window.addEventListener('resize', () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});

		const root = document.getElementsByTagName('html')[0];
		const header = document.querySelector('header');
		
		// submenu slide down
		let submenu = document.getElementById('projects/');
		let ch = submenu.clientHeight;
		submenu.style.setProperty('--ch', `${ch + 3}px`);
		submenu.style.height = '0';
		submenu.style.display = 'none';
		
		// close menu on submenu click
		submenu.addEventListener('click', function(){
			nav.classList.remove('is-open');
			header.classList.remove('vhide');
			root.classList.remove('bound');
		});
	
		// open and close submenu
		let allSubmenu = document.querySelectorAll('.has-submenu');
		Array.from(allSubmenu).forEach(function(submenu){
		
			submenu.childNodes[0].addEventListener('click', function(){
				if(submenu.classList.contains('actv')){
					submenu.classList.remove('actv');
					setTimeout(function(){
						submenu.querySelector('.submenu').style.display = 'none';
					}, 301)
				}
				else{
					submenu.querySelector('.submenu').style.display = 'block';
					setTimeout(function(){
						submenu.classList.add('actv');
					}, 50)
				}
			});
		});
	
		// open and close menu
		let openMenu = document.querySelector('#open-menu');
		let closeMenu = document.querySelector('#close-menu');
		let nav = document.querySelector('nav');
		let navCont = document.querySelector('nav .main-container');
	}
	
	handleClick(e){
		e.preventDefault();
		let root = document.getElementsByTagName('html')[0];
		let header = document.querySelector('header');
        let nav = document.querySelector('nav');

		let element = document.getElementById('footer');
		nav.classList.remove('is-open');
		header.classList.remove('vhide');
		root.classList.remove('bound');
		window.scrollTo({
			behavior: "smooth",
          	top:element.offsetTop
		});
	}


	getNavLinkClass = (path) => {
		let pathArr = this.props.location.pathname.split('/');
		return pathArr[pathArr.length - 2] === path.replace('/', '') ? `${path.replace('/', '')} actv` : `${path.replace('/', '')}`;
	}

	render() {
		let { options } = this.props.options;
        //console.log(options);
		let contact_number = options.contact_number ? options.contact_number : '';
		let contact_email = options.contact_email ? options.contact_email : '';
		let pinterest_url = options.pinterest_url ? options.pinterest_url : '';
		let instagram_url = options.instagram_url ? options.instagram_url : '';
		let twitter_url = options.twitter_url  ? options.twitter_url : '';
		let facebook_url = options.facebook_url ? options.facebook_url : '';
		let linkedin_url = options.linkedin_url ? options.linkedin_url : '';
		let copyright = options.copyright ? options.copyright : '';

		let { menu } = this.props.menu;
		let _this = this;
		let cls = 'submenu';
		let pathArr = this.props.location.pathname.split('/');

		//if(!menu.primary) return [];
		let menu_html = Object.keys(menu.primary).map((index) => {
			let item = menu.primary[index];
			let slug = item.url.replace(process.env.REACT_APP_WP_URL, '');
			let submenu_attr = {};
			let sub_menu_html = '';
			if (item.child_items.length) {
				let actvCls = (pathArr.length && pathArr[pathArr.length - 3] == slug.replace('/', '') || pathArr.length && pathArr[pathArr.length - 1] == slug.replace('/', '')) ? `${slug.replace('/', '')} has-submenu actv` : `${slug.replace('/', '')} has-submenu`;
				submenu_attr = { className: actvCls };
				if ( (pathArr.length && pathArr[pathArr.length - 3] == slug.replace('/', '')) ||  (pathArr.length && pathArr[pathArr.length - 1] == slug.replace('/', '')) ) {
					let submenu = document.getElementById('projects/');
					cls = 'submenu is-open';
					if(submenu)
						submenu.style.display = '';
				}
				
				else{
					let submenu = document.getElementById('projects/');
					if(submenu)
						submenu.style.display = 'none';
				}
				sub_menu_html = item.child_items.map((sub_item, sub_index) => {
					let sub_slug = process.env.PUBLIC_URL+"/"+sub_item.url.replace(process.env.REACT_APP_WP_URL, '');
                    //console.log(sub_slug);
                    sub_slug = sub_slug.replace('//', '/');
                    //console.log(sub_slug);
					return (
						<li key={sub_index}>
							<NavLink activeClassName="subnav-actv" exact to={sub_slug}>{sub_item.title}</NavLink>
						</li>
					);
				})
			}

			return (
				item.child_items.length ?
					<li {...submenu_attr} key={index}>
						<a activeClassName="nav-actv" href="javascript:void(0)" >{item.title}</a>
						<ul className={cls} id={slug}>
							{sub_menu_html}
						</ul>
					</li>
					:
					<li className={`navitem-${_this.getNavLinkClass(slug)}`} key={index}>
						<NavLink activeClassName="subnav-actv" exact to={`${process.env.PUBLIC_URL}/${slug}`}>{item.title}</NavLink>
					</li>
			);

		})
		return (
			<nav>
				<div className="main-container posiR">
					<div className="menu-grid">
						<label className="btn-closemenu" for="btn--menuToggle"></label>
						<ul className="mainmenu">
							{menu_html}
							<li className={`navitem-contact`}>
								<a onClick={_this.handleClick} data-to="#footer" href="javascript:void(0)">Contact</a>
							</li>
							<li className="navitem-contact-label"><label htmlFor="gotoContactSection" className="d-inline-block">Contact</label></li>
						</ul>

						<div className="contact-info">
							<div className="tmv-contact">
								<p><a href={`tel:${contact_number}`}>{contact_number}</a></p>
                                <p><a href={`mailto:${contact_email}`}>{contact_email}</a></p>
								{pinterest_url != '' &&
									<p><a href={pinterest_url} target="_blank">Pinterest</a></p>
								}
								{instagram_url != '' &&
									<p><a href={instagram_url} target="_blank">Instagram</a></p>
								}
								{facebook_url != '' &&
									<p><a href={facebook_url} target="_blank">Facebook</a></p>
								}
								{linkedin_url != '' &&
									<p><a href={linkedin_url} target="_blank">LinkedIn</a></p>
								}
							</div>

							<div className="tm-contact" dangerouslySetInnerHTML={{__html: copyright}} />
						</div>
					</div>
				</div>
			</nav>
		);
	}
}
export default Menu;