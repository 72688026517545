const initialState = {
    data:[],
    loading: true,
    error: null
};
export default function fetchProjects(state = initialState,action){
    switch(action.type) {
        case 'FETCH_PROJECTS_BEGIN':
            return {
                ...state,
                loading: true,
                error: null
            };
    
        case 'FETCH_PROJECTS_SUCCESS':
            return {
                ...state,
                loading: false,
                data:action.payload,
            };

        case 'FETCH_PROJECTS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data:[],
            };
    
        default:
          return state;
      }
}