import React from 'react';
import Header from './inc/Header';
import SiteFooter from './inc/SiteFooter';
import Loader from './inc/Loader';
import Menu from './inc/Menu';
import { fetchMenu } from "./../actions/menuActionCreators";
import { fetchProjects } from "./../actions/projectActionCreators";
import { fetchJournals } from "./../actions/journalActionCreators";
import { fetchOptions } from "./../actions/optionActionCreators";
import {
    NavLink,
    Link
} from 'react-router-dom'

class NoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true
        }
    }
    componentDidMount() {
        Promise.all([
            this.props.dispatch(fetchMenu('menu')),
            this.props.dispatch(fetchProjects()),
            this.props.dispatch(fetchJournals()),
            this.props.dispatch(fetchOptions()),
        ]).then(() => {
            this.setState({
                pageLoading: false
            })
        });
    }

    render() {
        return (
			<div className="fullW">
                <Header {...this.props} />
                    <main className="ll__journal">
                        <section>
                            <div className="main-container">
                                <p>In Progress</p>
                            </div>
                        </section>
                    </main>
                <Menu {...this.props} />
                <SiteFooter {...this.props} />
            </div>
        );
    }
}

export default NoPage;