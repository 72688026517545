import React from 'react';
import {
    NavLink,
    Link
} from 'react-router-dom'

class MenuToggleBtn extends React.Component {	
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleContactClick = this.handleContactClick.bind(this);
        document.getElementsByTagName('html')[0].classList.remove('bound');
    }

    handleClick(e){
        let {fullpageApi} = this.props;

        const root = document.getElementsByTagName('html')[0];
		const header = document.querySelector('header');
        let nav = document.querySelector('nav');
        let hammenu = document.querySelector('.btn-hammenu');
        
        if (document.getElementById('btn--menuToggle').checked) {
            // hammenu.classList.add('.close');
            nav.classList.add('is-open');
            header.classList.add('vhide');
            root.classList.add('bound');
            if(fullpageApi){
                fullpageApi.setAllowScrolling(false);
            }
        }
        else {
            // hammenu.classList.remove('.close');
            nav.classList.remove('is-open');
            header.classList.remove('vhide');
            root.classList.remove('bound');
            if(fullpageApi){
                fullpageApi.setAllowScrolling(true);
            }
        }
    }

    handleContactClick(e){
        let {fullpageApi} = this.props;

        const root = document.getElementsByTagName('html')[0];
		const header = document.querySelector('header');
        let nav = document.querySelector('nav');
        let hammenu = document.querySelector('.btn-hammenu');

        let fpage  = document.getElementById("fullpage");
        let fpageLast = fpage.childElementCount;
        if (document.getElementById('gotoContactSection').checked) {
            if(fullpageApi){
                nav.classList.remove('is-open');
                header.classList.remove('vhide');
                root.classList.remove('bound');
                fullpageApi.setAllowScrolling(true);
                fullpageApi.moveTo(fpageLast);
                document.getElementById('gotoContactSection').checked = false;
                document.getElementById('btn--menuToggle').checked = false;
            }
        }
    }

    render() {
        return (
            <div className="menu-toggleBtn">
                <label className="btn-hammenu" htmlFor="btn--menuToggle">
                    <input type="checkbox" id="btn--menuToggle" onClick={this.handleClick} />
                </label>

                <label htmlFor="gotoContactSection" className="moveToContact">
                    <input type="checkbox" id="gotoContactSection" onClick={this.handleContactClick} />
                </label>
            </div>
        );
    }
}

export default MenuToggleBtn;