import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Index from './../../components/Projects/List';

const mapStateToProps = (state) => {
    return {pages:state.pages,posts:state.posts,projects:state.projects,journals:state.journals,menu:state.menu,options:state.options};
}

const List = connect(mapStateToProps)(Index);

export default List;