import React from 'react';
import Header from './../../inc/Header';
import Loader from './../../inc/Loader';
import Menu from './../../inc/Menu';
import SiteFooter from './../../inc/SiteFooter';
import ScrollToTop from './../../inc/ScrollToTop';
import ProjectNav from './../ProjectNav';
import HeroLayout from './HeroLayout';
import RelatedProjects from './RelatedProjects';
import { fetchMenu } from "./../../../actions/menuActionCreators";
import { fetchProjects } from "./../../../actions/projectActionCreators";
import { fetchOptions } from "./../../../actions/optionActionCreators";
import ReactFullpage from '@fullpage/react-fullpage';
import ScrollArw from './../../inc/ScrollArw';
import MenuToggleBtn from './../../inc/MenuToggleBtn';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true
        }
    }
    componentDidMount() {
        let before = performance.now();
		let _this = this;
        Promise.all([
            this.props.dispatch(fetchMenu('menu')),
            this.props.dispatch(fetchProjects()),
            this.props.dispatch(fetchOptions()),
        ]).then(() => {
            let after = performance.now();
			let diff = after-before;
			let media_length = 3000;
			if(diff < media_length){
				setTimeout(() => {
					_this.setState({
						pageLoading: false
					})
				},(media_length - diff))
			}else{
				_this.setState({
					pageLoading: false
				})
			}
        });
    }

    render() {
		let { menu } = this.props.menu;
        let _this = this;
        let {pageLoading} = this.state;
        let projects = this.props.projects && Object.keys(this.props.projects.data).length ? this.props.projects.data.all : [];
        let details_slug = this.props.match.params.slug;
        let projectList = projects.filter((item) => {
            return item.post_name == details_slug;
        });

        if (projectList[0] === undefined) return [];

        //console.log(projectList[0]);

        let related_projects_arr = projectList[0].related_projects;
        let related_projects = [];

        if(related_projects_arr && related_projects_arr.length){
            related_projects = projects.filter((item) => {
                return related_projects_arr.indexOf( item.ID ) >= 0;
            });
        }

        
        const Fullpage = () => (
            <ReactFullpage
                normalScrollElements={'nav'}
                scrollOverflow={true}
				offsetSections={true}

                licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE}
                // responsiveWidth={process.env.REACT_APP_FULLPAGE_BREAKPOINT}
                afterLoad={
                    function (origin, destination, direction) {
                        var theme = destination.item.dataset ? destination.item.dataset.hammenu : false;
                        let header = document.querySelector('header');
                        const btmarw = document.querySelector('.btn--bottom-arw');
                        // const pharw = document.querySelector('.ph-arw');
                        if(theme){
							header.classList.add('white');
							if(btmarw)
                            	btmarw.classList.add('white');
                        }
                        else{
							header.classList.remove('white');
							if(btmarw)
                            	btmarw.classList.remove('white');
                        }
                        if(destination.isLast){
							if(btmarw)
                            	btmarw.classList.add('dir-up');
                        }
                        else{
							if(btmarw)
                            	btmarw.classList.remove('dir-up');
                        }
                        if(destination.isFirst){
							header.querySelector('.tmv__text').style.opacity = 1;
							// pharw.style.opacity = 0;
                        }
                        else{
							header.querySelector('.tmv__text').style.opacity = 0;
							// pharw.style.opacity = 1;
                        }
                    }
                }
                onLeave={
                    function (origin, destination, direction) {
                        let header = document.querySelector('header');
                        //console.log(destination)
                        if(destination.isFirst){
                            header.querySelector('.tmv__text').style.opacity = 1;
                        }
                        else{
                            header.querySelector('.tmv__text').style.opacity = 0;
                        }
                        //currentIndex = destination.index;
                    }
                }
                render={({ state, fullpageApi }) => {
                    let pgApi = fullpageApi;
                    let layoutData = projectList[0].layout.map((row, index) => {
                        return (
                            <div className="section" key={index} >
                                {
                                    !pageLoading &&
                                    <MenuToggleBtn {...this.props} fullpageApi={pgApi} />

                                }
                                <div dangerouslySetInnerHTML={{__html:row}} />
                                <ScrollArw {..._this.props}  handleClickBelow={ () => { pgApi && pgApi.moveSectionDown()} } />
                            </div>)
                    })
                    return (
                        <ReactFullpage.Wrapper>
                            <HeroLayout {..._this.props} details={projectList[0]} fullpageApi={fullpageApi} />
                            {layoutData}
                            <RelatedProjects {..._this.props} related_projects={related_projects} fullpageApi={fullpageApi}  />
                            <SiteFooter {..._this.props} fullpageApi={fullpageApi} />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        );

        return (
               
                <main className="tmv__project-dtls">
                     {this.state.pageLoading &&
                        <Loader />
                    }
                    <ScrollToTop />
                    <Header headerTheme={'white'} {..._this.props} fullpage={true} />
                    {<a href="javascript:" className="btn--bottom-arw ph-arw"></a> /* fake :-) :-) :-) :-) */}
                    <Fullpage {...this.props} />
                    {menu.primary &&
                        <Menu {...this.props} />
                    }
                </main>
                
        );
    }
}

export default Index;