import React from 'react';
import MenuToggleBtn from './MenuToggleBtn';
import {
    NavLink,
    Link
} from 'react-router-dom'

class Header extends React.Component {	
    constructor(props){
        super(props);
        this.handleClick    = this.handleClick.bind(this);
    }

    componentDidMount(){
        let {menu}              = this.props;
        window.addEventListener( "pageshow", function ( event ) {
            var historyTraversal = event.persisted || 
                                   ( typeof window.performance != "undefined" && 
                                        window.performance.navigation.type === 2 );
            if ( historyTraversal ) {
              // Handle page restore.
              window.location.reload();
            }
        });

        document.querySelector('header .moveToContact').remove();
    }

    handleClick = () => {
        
    }

    render() {
        let {options}   = this.props.options;
        let logoStyle   = this.props.noLogo ? {visibility:'hidden'} : {};
        let bgColor     = this.props.bgColor ? {backgroundColor:this.props.bgColor} : {};
        let headerTheme     = this.props.headerTheme ? this.props.headerTheme : '';
        return (
            <header className={headerTheme}>
                <div className="main-container">
                    <Link to={`${process.env.PUBLIC_URL}/`} className="tmv__text">
                        <h1>TMV Architects</h1>
                    </Link>

                    <MenuToggleBtn/> 

                    {/* {
                        this.props.fullpage &&
                        <a href="javascript:" className="btn-hammenu"></a>
                    }
                    {
                        !this.props.fullpage &&
                            <MenuToggleBtn/>
                    } */}
                </div>
            </header>
        );
    }
}

export default Header;