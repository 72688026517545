import React from 'react';

class TmvLogo extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let {logo_image, black_logo_image} = this.props.options.options;
        let logo = this.props.bLogo ? black_logo_image : logo_image;
        let {pos} = this.props;
        return (
            <figure className={`tmv__logo w-100 ${pos}`}>
                <div className="main-container">
                    <img src={logo} alt="TMV Architects" title="TMV Architects" />
                </div>
            </figure>
        );
    }
}
export default TmvLogo;
