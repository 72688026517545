import React from 'react';
// containers
import Home from './containers/Home';
import Practice from './containers/Practice';
import ProjectList from './containers/Projects/List';
import ProjectDetails from './containers/Projects/Details';
import JournalList from './containers/Journals/List';
import Contact from './containers/Contact';
import NoPage from './containers/NoPage';


import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

function Layout(){
	return(
		<Router >
			<div className="fullW">	
				<Switch>
					<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
					<Route exact path={`${process.env.PUBLIC_URL}/practice`} component={Practice}/>
					<Route exact path={`${process.env.PUBLIC_URL}/projects`} component={ProjectList}/>
					<Route exact path={`${process.env.PUBLIC_URL}/projects/:category`} component={ProjectList}/>
					<Route exact path={`${process.env.PUBLIC_URL}/project/:slug`} component={ProjectDetails}/>
					<Route exact path={`${process.env.PUBLIC_URL}/journals`} component={JournalList}/>
					<Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
				</Switch>
			</div>
		</Router>
	)
}
export default Layout;