import React from 'react';
import Header from './../inc/Header';
import Loader from './../inc/Loader';
import Menu from './../inc/Menu';
import SiteFooter from './../inc/SiteFooter';
import MainSlider from './../Practice/MainSlider';
import Intro from './../Practice/Intro';
import Services from './../Practice/Services';
import People from './../Practice/People';
import { fetchMenu } from "../../actions/menuActionCreators";
import { fetchPage } from "../../actions/pageActionCreators";
import { fetchProjects } from "../../actions/projectActionCreators";
import { fetchOptions } from "../../actions/optionActionCreators";

import ReactFullpage from '@fullpage/react-fullpage';
const page_id = '9';

class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoading: true
		}
	}
	componentDidMount() {
		Promise.all([
			this.props.dispatch(fetchMenu('menu')),
			this.props.dispatch(fetchPage(page_id)),
			this.props.dispatch(fetchProjects()),
			this.props.dispatch(fetchOptions()),
		]).then(() => {
			this.setState({
				pageLoading: false
			})
		});
	}

	render() {
		let _this = this;
		let pageData = this.props.pages.pageData[page_id];
		let { menu } = this.props.menu;

		const Fullpage = () => (
			<ReactFullpage
				normalScrollElements={'nav'}
				scrollOverflow={true}
				licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE}
				responsiveWidth={process.env.REACT_APP_FULLPAGE_BREAKPOINT}
				render={({ state, fullpageApi }) => {
					return (
						<ReactFullpage.Wrapper>
							<MainSlider {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<Intro {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<Services {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<People {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<SiteFooter className="active" {..._this.props} fullpageApi={fullpageApi} />
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		);

		return (
			<main className="tmv__practice">
				{this.state.pageLoading &&
					<Loader />
				} 
				<Header {..._this.props} fullpage={true} />
				<Fullpage {..._this.props} />
				{menu.primary &&
					<Menu {...this.props} />
				}
			</main>
		);
	}
}

export default Index;