import React from 'react';
import {
    NavLink,
    Link
} from 'react-router-dom'

class ScrollArw extends React.Component {	
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        let {cls, fullpageApi, moveTo, handleClickBelow, nScroll} = this.props;
        if( !nScroll || nScroll == undefined){
            e.preventDefault();
            if(handleClickBelow){
                handleClickBelow();
            }else{
                if(moveTo){
                    fullpageApi.moveTo(moveTo)
                }else{
                    fullpageApi.moveSectionDown()
                }
            }
        }
    }

    render() {
        let {cls} = this.props;
        return (
            <a href="#"  className={`backToTop btn--bottom-arw ${cls}`} onClick={ this.handleClick } ></a>
        );
    }
}

export default ScrollArw;