const initialState = {
    data:[],
    next_pg:null,
    loading: true,
    error: null
};
export default function fetchJournals(state = initialState,action){
    switch(action.type) {
        case 'FETCH_JOURNALS_BEGIN':
            return {
                ...state,
                loading: true,
                error: null
            };
    
        case 'FETCH_JOURNALS_SUCCESS':
            return {
                ...state,
                loading: false,
                data:action.payload.data,
                next_pg:action.payload.next_pg
            };
    
        case 'FETCH_JOURNALS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data:[],
                next_pg:null
            };
    
        default:
          return state;
      }
}