import React from 'react';
import {
    NavLink,
    Link
} from 'react-router-dom'

class ProjectNav extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        let submenu = document.getElementById('project-filter');
        submenu.style.setProperty('--ch', `${(submenu.clientHeight)}px`);
        submenu.style.height = '0';
        submenu.style.display = 'none';
    }

    render() {
        let { menu } = this.props.menu;
        let {data} = this.props.projects;
        //if(!Object.keys(data).length) return [];
        let sub_menu_html = Object.keys(menu.primary).map((index) => {
			let item = menu.primary[index];
			let slug = item.url.replace(process.env.REACT_APP_WP_URL, '');
            if(slug.replace('/', '') == 'projects'){
                if (item.child_items.length) {
                    return item.child_items.map((sub_item, sub_index) => {
                        //let sub_slug = sub_item.url.replace(process.env.REACT_APP_WP_URL, '');
                        let sub_slug = process.env.PUBLIC_URL+"/"+sub_item.url.replace(process.env.REACT_APP_WP_URL, '');
                        sub_slug = sub_slug.replace('//', '/');
                        return (
                            <li key={sub_index}>
                                <NavLink activeClassName="subnav-actv" exact to={sub_slug}>{sub_item.title}</NavLink>
                            </li>
                        );
                    })
                }
            }
        });

        return (
            <div className="main-container">
                <ul className="mainmenu">
                    <li className="has-submenu">
                        <a href="javascript:">Filter</a>
                        <ul className="submenu" id="project-filter">
                            {sub_menu_html}
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
}

export default ProjectNav;