import React from 'react';
//import video from './../../assets/media/TMV_1260x410.mp4';
import gif from './../../assets/media/TMV_1260x410.gif';
import Cookies from 'universal-cookie';



class Loader extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount(){
		// adjust fullscreen height
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		window.addEventListener('resize', () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		});
	}

	render() {
        const cookies = new Cookies();
        let loaderShowed = cookies.get('loaderShowed');
        //console.log(loaderShowed); 

        if( typeof loaderShowed === 'undefined'
            || loaderShowed === null
        ) {
            //console.log('show Loader'); 

            //Wait for all DOM elements to load, otherwise the loader will abruptly hide before it has finished displaying.
            setTimeout(() => {
                cookies.set(
                    'loaderShowed', 
                    true, 
                    { 
                        path: '/', 
                        sameSite: 'strict',
                        expires: new Date(Date.now()+1000*3600*24*30) //One month
                    }
                );
              }, 1000)

            return (
                <section id="tmv__loader">
                    <div className="main-container">
                        {/* <video muted preload="auto" autoPlay="true" >
                            <source src={video} type="video/mp4" />
                        </video> */}
    
                        <figure className="mb-0">
                            <img src={gif} alt="TMV Architects" title="TMV Architects"/>
                        </figure>
                    </div>
                </section>
            );

        }   
        //console.log('hide Loader'); 
		return (null);
	}
}
export default Loader;