import React from 'react';
import Header from './../inc/Header';
import Loader from './../inc/Loader';
import Menu from './../inc/Menu';
import SiteFooter from './../inc/SiteFooter';
import ScrollToTop from './../inc/ScrollToTop';
import MainSlider from './MainSlider';
import Intro from './Intro';
import Services from './Services';
import People from './People';
import { fetchMenu } from "../../actions/menuActionCreators";
import { fetchPage } from "../../actions/pageActionCreators";
import { fetchProjects } from "../../actions/projectActionCreators";
import { fetchOptions } from "../../actions/optionActionCreators";

import ReactFullpage from '@fullpage/react-fullpage';
import ScrollArw from './../inc/ScrollArw';
import MenuToggleBtn from './../inc/MenuToggleBtn';

import { gsap } from "gsap";

const page_id = '9';

class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoading: true
		}
	}
	componentDidMount() {
		let before = performance.now();
		let _this = this;
		Promise.all([
			this.props.dispatch(fetchMenu('menu')),
			this.props.dispatch(fetchPage(page_id)),
			this.props.dispatch(fetchProjects()),
			this.props.dispatch(fetchOptions()),
		]).then(() => {
			let after = performance.now();
			let diff = after-before;
			let media_length = 3000;
			if(diff < media_length){
				setTimeout(() => {
					_this.setState({
						pageLoading: false
					})
				},(media_length - diff))
			}else{
				_this.setState({
					pageLoading: false
				})
			}
		});
	}

	render() {
		let _this = this;
		let pageData = this.props.pages.pageData[page_id];
		let { menu } = this.props.menu;

        if (pageData === undefined) return [];

		const Fullpage = () => (
			<ReactFullpage
				normalScrollElements={'nav, .tmv__team--member-dtls'}
				scrollOverflow={true}
                scrollHorizontally={false}
				licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE}
				// responsiveWidth={process.env.REACT_APP_FULLPAGE_BREAKPOINT}
				onLeave={
                    function (origin, destination, direction) {
						if(destination.item.querySelector('.tmv__service--swiper')){
							document.querySelector('.btn--bottom-arw').style = 'opacity: 0; pointer-events: none;';
						}
						else{
							document.querySelector('.btn--bottom-arw').removeAttribute('style');
						}
					}
				}
				afterLoad={
                    function (origin, destination, direction) {
                        var theme = destination.item.dataset ? destination.item.dataset.hammenu : false;
                        let header = document.querySelector('header');
                        const btmarw = document.querySelector('.btn--bottom-arw');
                        if(theme){
							header.classList.add('white');
							if(btmarw)
                            	btmarw.classList.add('white');
                        }
                        else{
							header.classList.remove('white');
							if(btmarw)
                            	btmarw.classList.remove('white');
                        }
                        if(destination.isLast){
							if(btmarw)
                            	btmarw.classList.add('dir-up');
                        }
                        else{
							if(btmarw)
                            	btmarw.classList.remove('dir-up');
                        }
                        if(destination.isFirst){
							header.querySelector('.tmv__text').style.opacity = 1;
                        }
                        else{
							header.querySelector('.tmv__text').style.opacity = 0;
						}
						
						// gsap for intro, team content, service box
						gsap.defaults({
							autoAlpha: 1,
							stagger: 0.2, 
							duration: 0.3,
							ease: "Power1.easeOut"
						});

						if(destination.item.querySelector('.tmv__intro')){
							gsap.to(".tmv__intro article > *", {
								duration: 0.9,
								ease: "Power0.easeNone"
							});
						}
						
						if(destination.item.querySelector('.tmv__team')){
							gsap.to(".tmv__team--member .--name", {});
							gsap.to(".tmv__team--member .--degination", {});
						}

						if(destination.item.querySelector('.tmv__service--grid')){
							gsap.to(".tmv__service--grid > article", {});
						}

						if(destination.item.querySelector('.tmv__service--swiper')){
							gsap.to(".tmv__service--swiper article", {});
						}
                    }
                }
				render={({ state, fullpageApi }) => {
                    
                    let layoutData = pageData.layout.map((row, index) => {
                        return (
                            <div className="section" key={index} >
                                {
                                    !this.state.pageLoading &&
                                    <MenuToggleBtn {...this.props} fullpageApi={fullpageApi} />

                                }
                                <div dangerouslySetInnerHTML={{__html:row}} />
                                <ScrollArw {..._this.props}  handleClickBelow={ () => { fullpageApi && fullpageApi.moveSectionDown()} } />
                            </div>)
                    })
					return (
						<ReactFullpage.Wrapper>
							<MainSlider {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<Intro {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<People {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
                            {layoutData}
							<Services {..._this.props} page_id={page_id} fullpageApi={fullpageApi} />
							<SiteFooter {..._this.props} fullpageApi={fullpageApi} />
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		);

		return (
			<main className="tmv__practice">
				{this.state.pageLoading &&
					<Loader />
				} 
				<ScrollToTop />
				<Header {..._this.props} headerTheme={'white'} fullpage={true} />
				{<a href="javascript:" className="btn--bottom-arw ph-arw"></a> /* fake :-) :-) :-) :-) */}
				<Fullpage {..._this.props} />
				{menu.primary &&
					<Menu {...this.props} />
				}
			</main>
		);
	}
}

export default Index;