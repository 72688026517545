import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import TmvLogo from './../inc/TmvLogo';
import ScrollArw from './../inc/ScrollArw';
import MenuToggleBtn from './../inc/MenuToggleBtn';

class MainSlider extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() { }

	render() {
		let page_id = this.props.page_id;
		let sliders = this.props.pages.pageData[page_id] ? this.props.pages.pageData[page_id].acf.hero_video_section : [];
		let title = this.props.pages.pageData[page_id] ? this.props.pages.pageData[page_id].title.rendered : '';
		let sliderData = sliders && sliders.map((item, key) => {
            return (
                <div key={key} className={item.image_type}>
                    <div className="main-container">
						<figure className="hero-fig">
                            <img className="fullScreen" src={item.image.url} alt={item.image.alt} title={item.image.title} />
                        </figure>
                    </div>
                    <TmvLogo {...this.props} />
                </div>
            )
        })
		let carouselOption = (sliders.length > 1) ? true : false;

		return (
			<section className="section bg-black" data-hammenu="white">
				<MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
				<div className="tmv__hero">
					<AliceCarousel 
                        autoPlay={carouselOption}
                        autoPlayInterval="3000"
                        animationType="fadeout"
                        infinite={carouselOption}
                        disableDotsControls="true"
                        disableButtonsControls="false"
						// mouseTracking="true"
                        items={sliderData} 
                    />
					<h2 className="page-title">{title}</h2>
				</div>
				<ScrollArw {...this.props} fullpageApi={this.props.fullpageApi} />
			</section>
		);
	}
}

export default MainSlider;