export const LayoutOne = [
    '1-top-right',
    '1-top-left',
    '1-bottom-left',
    '1-bottom-middile',
    '1-bottom-right',
    '2-top-left',
    '2-top-right',
    '2-bottom-left',
    '2-bottom-middile',
    '2-bottom-right',
]