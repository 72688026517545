import React, { useRef, useEffect, useState } from 'react';
import Header from './../../inc/Header';
import SiteFooter from './../../inc/SiteFooter';
import Loader from './../../inc/Loader';
import Menu from './../../inc/Menu';
import TmvLogo from './../../inc/TmvLogo';
import { fetchMenu } from "./../../../actions/menuActionCreators";
import { fetchJournals } from "./../../../actions/journalActionCreators";
import { fetchOptions } from "./../../../actions/optionActionCreators";
import ScrollToTop from './../../inc/ScrollToTop';


import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            data:[],
            pg:1,
            loadingTxt:'Load More'
        }
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        let headerElem = document.querySelector('header');
        
        let before = performance.now();
		let _this = this;
        Promise.all([
            this.props.dispatch(fetchMenu('menu')),
            this.props.dispatch(fetchJournals()),
            this.props.dispatch(fetchOptions()),
        ]).then(() => {
            let after = performance.now();
			let diff = after-before;
			let media_length = 3000;
			if(diff < media_length){
				setTimeout(() => {
					_this.setState({
						pageLoading: false
					})
                   //  headerElem.classList.remove('white');
				},(media_length - diff))
			}else{
				_this.setState({
                    pageLoading: false,
				})
               //  headerElem.classList.remove('white');
            }
            setTimeout(() => {
                let { data } = _this.props.journals;
                _this.setState({
                    data:[...data]
                })
            },1000)
            
            
            setTimeout(function(){
                // gsap-> fade logo before footer comes into viewport
                gsap.to('.tmv__logo', {
                    opacity: 0, 
                    scrollTrigger:{
                        trigger: 'footer',
                        start: '-=700, center',
                        end: 'top center',
                        scrub: true,
                    }
                });

                // gsap-> journal item reveal
                gsap.set(".tmv__journal--item", {autoAlpha: 0, y: '300px'})
                ScrollTrigger.batch(".tmv__journal--item", {
                    onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.3,  duration: 0.8, ease: "sine.out"}),
                });

                // gsap-> add white class to header
                // ScrollTrigger.create({
                //     trigger: '.tmv__journal',
                //     onToggle: ({isActive}) => {
                //         if(isActive){
                //             document.querySelector('header').classList.remove('white');
                //         }
                //         else{
                //             document.querySelector('header').classList.add('white');
                //         }
                //     }
                // })

                
                // gsap-> add white class to header
                ScrollTrigger.create({
                    trigger: 'footer',
                    start: 'top-=10 top',
                    end: 'bottom top-=50000',
                    toggleClass: {targets: 'header', className: "white"},
                    // markers: true,
                    // id: 'elem'
                });
            }, 1800)
           
            // align journal grid with logo
            const getOffset = function(){
                let logoHeight      = document.querySelector('.tmv__logo').offsetHeight;
                let headerHeight    = document.querySelector('header').offsetHeight;
                let winHeight       = window.innerHeight;
                let remainingHeight = Math.abs(winHeight - logoHeight) / 2;
                let offsetTop       = Math.abs(remainingHeight - headerHeight);
                document.documentElement.style.setProperty('--logoOffsetTop', `${offsetTop}px`);
            }
            setTimeout(() => { getOffset()}, 1000)
            window.addEventListener('resize', () => getOffset());
        });
    
    }

    handleClick(e){
		e.preventDefault();
        let _this = this;
        let {pg} = _this.state;
        _this.setState(
            prevstate => ({
                loadingTxt:'Loading ...'
            })
        )
        Promise.all([
            this.props.dispatch(fetchJournals(pg+1))
        ]).then(() => {
            let { data } = _this.props.journals;
            let sData = this.state.data;
            let mData = sData.concat(data);
            _this.setState(
                prevstate => ({
                    ...prevstate,
                    data:mData,
                    pg:pg+1,
                    loadingTxt:'Load More'
                })
            )
            setTimeout(function(){
                // gsap-> journal item reveal
                gsap.set(`.batch-${pg+1}`, {autoAlpha: 0, y: '300px'})
                ScrollTrigger.batch(`.batch-${pg+1}`, {
                    onEnter: batch => gsap.to(batch, {autoAlpha: 1, y: 0, stagger: 0.3,  duration: 0.8, ease: "sine.out"}),
                });
                
                window.scrollBy(0, -50);
                ScrollTrigger.refresh(true);
            },100)
            
        });
    }

    render() {
        let { menu } = this.props.menu;
        let { next_pg } = this.props.journals;
        let {data, pg, loadingTxt} = this.state;
        let counter = 1;
        let feed = data.map((item, index) => {
            let sub_item = Object.keys(item.dataArr).map((key, i) => {
                let single = item.dataArr[key];
                let iterator = Math.ceil(Number(counter)/30);
                let cls = `batch-${iterator}`;
                counter++;
                return (
                    <div className={`tmv__journal--item ${key} ${cls}`} key={i}>
                        <figure>
                            {
                                single.media_type == 'VIDEO' ?
                                    <video muted="" loop="" playsinline="" poster="">
                                        <source src={single.media_url} type="video/mp4" />
                                    </video>
                                : <img src={single.media_url} />

                            }
                            <div className="figinfo">
                                <p>{single.caption}</p>
                            </div>
                        </figure>
                        <div className="figdate">{single.date}</div>
                        <a className="journal-link" href={single.link} target="_blank"></a>
                    </div>
                )
            })
            return (
                <div className={`grid-${item.clsPrimary}`} key={index}>
                    {sub_item}
                </div>
            )
        });
        return (
            <div className="fullW">
                {this.state.pageLoading &&
					<Loader />
				} 
				<ScrollToTop />
				<Header {...this.props} fullpage={false} />
                <main className="tmv__journal posiR">
                    <div className="inner-container">
                        <div className="main-container">        
                            <div className="tmv__journal--grids">
                                <TmvLogo {...this.props} pos='fixed' bLogo={true} />
                                {feed}
                            </div>
                        </div>
                    </div>
                    {next_pg != null &&
                        <a href="javascript:void(0)" class="btn--loadmore" onClick={this.handleClick}><span>{loadingTxt}</span></a>

                    }
                    
                </main>
                {menu.primary &&
                    <Menu {...this.props} />
                }
				<SiteFooter {...this.props} nScroll={true} />
            </div>
        );
    }
}

export default Index;
