import React from 'react';
import SwiperCore, { Autoplay, A11y, Navigation } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import TmvLogo from './../inc/TmvLogo';


import ScrollArw from './../inc/ScrollArw';
import MenuToggleBtn from './../inc/MenuToggleBtn';

SwiperCore.use([ Autoplay, A11y, Navigation]);

class Services extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let page_id = this.props.page_id;
    let sectionData = this.props.pages.pageData[page_id] ? this.props.pages.pageData[page_id].acf.services_section : [];
    if(!Object.keys(sectionData).length) return [];
    let {heading, services} = sectionData;
    let slideData = services.map((service, key) => {
        return (
            <SwiperSlide key={key}>
                <article>
                    <p><b>{service.title}</b></p>
                    <div dangerouslySetInnerHTML={{__html: service.details}} />
                </article>
            </SwiperSlide>
        )
    })
    
    return (
        
        <section className="section">
            <MenuToggleBtn {...this.props} fullpageApi={this.props.fullpageApi} />
           <div className="tmv__services content-area">
               <div className="main-container">
                    <h3 className="section-title font-bold">{heading}</h3>
                    <div className="tmv__service--swiper">
                        <Swiper  
                            autoplay="false"
                            navigation={{
                                prevEl: '.swiper-service--prev',
                                nextEl: '.swiper-service--next',
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 30,
                                },
                                760: {
                                  slidesPerView: 3,
                                  spaceBetween: 30,
                                },
                                1001: {
                                  slidesPerView: 4,
                                  spaceBetween: 30,
                                },
                              }}
                        >
                            {slideData}

                            <div className="swiper-service--nav">
                                <span className="swiper-service--prev"></span>
                                <span className="swiper-service--next"></span>
                            </div>
                                
                        </Swiper>
                        
                    </div>
               </div>
           </div>
           <ScrollArw {...this.props} fullpageApi={this.props.fullpageApi} />
        </section>
    );
  }
}

export default Services;