import React from 'react';
import Header from './../../inc/Header';
import Loader from './../../inc/Loader';
import Menu from './../../inc/Menu';
import SiteFooter from './../../inc/SiteFooter';
import ScrollToTop from './../../inc/ScrollToTop';
import ProjectNav from './../ProjectNav';
import { fetchMenu } from "./../../../actions/menuActionCreators";
import { fetchProjects } from "./../../../actions/projectActionCreators";
import { fetchOptions } from "./../../../actions/optionActionCreators";
import {LayoutOne} from "./../GridLayouts/1";


import {
    NavLink,
    Link
} from 'react-router-dom'


import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);



class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            all:[]
        }
    }
    componentDidMount() {
        let headerElem = document.querySelector('header');
        
        let before = performance.now();
		let _this = this;
        Promise.all([
            this.props.dispatch(fetchMenu('menu')),
            this.props.dispatch(fetchProjects()),
            this.props.dispatch(fetchOptions()),
        ]).then(() => {
            let projects = this.props.projects && Object.keys(this.props.projects.data).length ? this.props.projects.data.all : [] ;
            this.setState({
                all:projects,
            })
            let projectGrids = document.querySelectorAll('.tmv__project--item');

            Array.from(projectGrids).forEach(function(tm){
                tm.addEventListener('mouseover', function(){
                    [...projectGrids].forEach(x => x.classList.add(['fade-in']));
                    this.classList.remove(['fade-in']);
                });
                tm.addEventListener('mouseleave', function(){
                    [...projectGrids].forEach(x => x.classList.remove(['fade-in']));
                });
            });

            let after = performance.now();
			let diff = after-before;
			let media_length = 3000;
			if(diff < media_length){
				setTimeout(() => {
					_this.setState({
						pageLoading: false
					})
                   //  headerElem.classList.remove('white');
				},(media_length - diff))
			}else{
				_this.setState({
					pageLoading: false
				})
               //  headerElem.classList.remove('white');
			}
          
            setTimeout(function(){
                ScrollTrigger.create({
                    trigger: '.tmv__projects--grid',
                    onToggle: ({isActive}) => {
                        if(isActive){
                            document.querySelector('header').classList.remove('white');
                        }
                        else{
                            document.querySelector('header').classList.add('white');
                        }
                    },
                })
            }, 800)
      
        });
    }

    render() {
		let { menu } = this.props.menu;
        let {category} = this.props.match.params;
        let projects = this.state.all;
        let filtered = [];
        if(category){
            projects.map((project) => {
                if(project.categories.length){
                    project.categories.map((cat) => {
                        if(cat.slug == category) {
                            filtered.push(project)
                        }
                    })
                }
                
            })
            projects = filtered;
            setTimeout(function(){
                ScrollTrigger.create({
                    trigger: '.tmv__projects--grid',
                    onToggle: ({isActive}) => {
                        if(isActive){
                            document.querySelector('header').classList.remove('white');
                        }
                        else{
                            document.querySelector('header').classList.add('white');
                        }
                    },
                })
            }, 500)
        }
        let projectsData = projects.map((item, key) => {
            let index = key <= LayoutOne.length - 1 ? key : key % (LayoutOne.length - 1);
            let imgName = item.hero_image && item.hero_image.sizes ? item.hero_image.sizes[LayoutOne[index]] : false;
            let sequence = ("0" + (Number(key) + 1) ).slice(-2);
            let textColour = item.project_listing_colour ? item.project_listing_colour: 'white';

            return (
                <figure className="tmv__project--item">
                    <Link to={`${process.env.PUBLIC_URL}/project/${item.post_name}`} >
                        <img src={imgName} alt={item.post_title} title={item.post_title} />
                        <figcaption style={{color: textColour}}>
                            {item.post_title != '' &&
                                <h6 className="title" data-counter={sequence}>{item.post_title}</h6>
                            }
                            {item.project_subtitle != '' &&
                                <p className="sub-title">{item.project_subtitle}</p>
                            }
                        </figcaption>
                    </Link>
                </figure>
            )
        })
        return (
            <div className="fullW">
                {this.state.pageLoading &&
                    <Loader />
                } 
                <ScrollToTop />
                <Header {...this.props} />
                <main className="tmv__projects">
                    {menu.primary &&
                        <ProjectNav {...this.props} fullpage={false} />
                    }

                    <div className="main-container">
                        <div className="tmv__projects--grid">
                            {projectsData}
                        </div>
                    </div>
                </main>
                <SiteFooter {...this.props}  nScroll={true} />
                {menu.primary &&
                    <Menu {...this.props} />
                }
            </div>

        );
    }
}

export default Index;