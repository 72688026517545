import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Index from './../../components/Projects/Details';

const mapStateToProps = (state) => {
    return {projects:state.projects,menu:state.menu,options:state.options};
}

const Details = connect(mapStateToProps)(Index);

export default Details;